&__cell {
    flex: 0 0 50%;
    max-width: rem(1000px);
    padding: rem($comp-pad);

    @media all and (max-width: $bp-med) {
        flex: 0 0 100%;
        max-width: 100%;
    }

    &-cozy {
        padding: rem(calc($comp-pad * 2));
        @media all and (min-width: $bp-med) {
            padding: rem(calc($comp-pad * 4));
        }
        svg{
            max-width: 100%;
        }
    }

    &-compact-y {
        padding-top: rem(calc($comp-pad / 2));
        padding-bottom: rem(calc($comp-pad / 2));
    }

    &-fw {
        flex: 0 0 100%;
        max-width: 100%;

        .mc-forecast .glide__slide & {
            @include display(flex, row wrap, null, center);
        }
    }
    &-hw {
        flex: 0 0 50%;
        max-width: 50%;
        
        .mc-forecast .glide__slide & {

            &:nth-child(odd) {
                padding-right: 0;
            }
            &:nth-child(even) {
                padding-left: 0;
            }
        }
    }

    @media all and (min-width: $bp-sml) {

        &-hw-sm {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    @media all and (min-width: $bp-med) {

        &-fw-md {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &-hw-md {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    @media all and (min-width: $bp-lrg) {

        &-fw-lg {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &-hw-lg {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &-25 {
        flex: 0 0 100%;
        max-width: 100%;

        @media all and (min-width: $bp-sml) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media all and (min-width: rem(600px)) {
            flex: 0 0 calc(100% /3);
            max-width: calc(100% /3);
        }
        @media all and (min-width: $bp-med) {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }

    

    &.pb-0 {
        padding-bottom: 0;
    }
    &.mr-auto {
        margin-right: auto;
    }

    &-shrink {
        flex: 0 1 auto;

        @media all and (min-width: $bp-lrg) {
            &-lg {
                flex: 0 1 auto;
            }
        }
        &-sm {
            @media all and (min-width: $bp-sml) {
                flex: 0 1 auto;
            }
        }
    }

    &-grow {
        flex: 1 0 50%;
    }

    &-reverse {
        @include display(flex, column-reverse nowrap);
        padding-bottom: 0;
    }

    &:not(.mc__cell-fw) {
        .mc-weather .mc__cell .mc__row:not(.mc__row-stack) & {
            @media all and (max-width: $bp-med) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .mc__row-up5 > & {

        @media all and (min-width: $bp-med) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media all and (min-width: rem(1240px)) {
            flex: 0 0 25%;
            max-width: 25%;
        }
        @media all and (min-width: $bp-xlrg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}
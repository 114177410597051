.hero {
    @include position;
    padding-bottom: 0;

    &__logo {
        @include position(absolute, 1, (left, bottom), (50%, calc($comp-pad * 2)), translateX(-50%));
        
            svg {
                @media all and (max-width: $bp-sml) {
                    @include size(rem(74px));
                }
                @media all and (max-width: $bp-med) {
                    @include size(rem(88px));
                }
        }
    }

    &.mc-hero {
        overflow: hidden;
    }
}

.hero__hgroup{
    @include gutter2();
    @include position(relative, 1);
    margin-top: 100px;
    margin-bottom: 3rem;
    
    @media all and (min-width: $bp-lrg) {
        margin-top: calc(75px + 4vw);
    }
    .hero__media + &,
    .hero__video-container + &{
        margin-top: 0;
        margin-bottom: 0;
        transform: translateY(-50%);

        .mc-hero & {
            transform: translateY(-75%);
        }
    }

    .mc-hero & {
        @include position(absolute, 2);
        top: 50%;
        @include gutter1();
    }
}

.hero__media{
    display: block;
    height: 100%;
    position: relative;
    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 40, 86, 0.40) 0%, rgba(255, 255, 255, 0.00) 84.38%);
        mix-blend-mode: multiply;
    }
}

.hero__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hero__video-container{
    // position: relative;
    background-color: #000;
    // overflow: hidden;
    // width: 100%;
    // height: 0;
    // padding-bottom: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vw;
    max-height: 900px;
    overflow: hidden;
    @media all and (min-width: $bp-lrg) {
        max-height: none;
        height: 50vw;
        //transform: translate($hero-img-offset, 0px);
    }
    &:before{
        content: "";
        display: block;
        width: 100%;
        height: 30%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 40, 86, 0.40) 0%, rgba(255, 255, 255, 0.00) 84.38%);
        mix-blend-mode: multiply;
        z-index: 2;
    }
}
.hero____video{
    position: absolute;
    z-index: 1;
    top: 50%;
    left:50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
}
.hero__heading{
    position: relative;
    color: $white;
    display: inline-block;
    background-color: $brand-primary;
    border-radius: 0 25px 25px 0;
    padding: 25px 25px 25px 0;
    margin: 0;
    z-index: 1;
    font-size: #{fluid(45px, 72px)};
    line-height: 1;
    @media all and (min-width: $bp-lrg) {
        padding: 30px 30px 30px 0;
    }

    .mc-hero & {
        @include display(flex, column nowrap);
        background: none;
        padding: 0;
        font-size: clamp(rem(60px), 15vw, rem(100px));
        @media all and (min-width: $bp-lrg) {
            font-size: clamp(rem(100px), 10vw, rem(140px));
        }
        font-weight: 700;
        word-spacing: 100vw;
        word-break: break-all;
    }

    .hero__hgroup & {

        &:before{
            content: "";
            @include display(block);
            @include position(absolute, -1, (top, right), (0, rem($comp-pad)));
            @include size(100vw, 100%);
            background-color: $brand-primary;

            .mc-hero & {
                content: none;
            }
        }
    }
}
